import { Route, Switch } from 'react-router-dom';

import './App.css';
import UsersPages from './pages/UsersPage';
import AddUserPage from './pages/AddUserPage';
import ShoppingPage from './pages/ShoppingPage';
import UploadUsersPage from './pages/UploadUsersPage';
import Layout from './components/layout/Layout';
import RemainQrCodesPage from './pages/RemainQrCodesPage';
import SettingsPage from './pages/SettingsPage';
import RegistrationPage from './pages/RegistrationPage';
import LoginPage from './pages/LoginPage';
import ForgotPassword from './pages/ForgotPasswordPage';
import ResetPassword from './pages/ResetPasswordPage';

function App() {
  return (
    <Layout>
      <Switch>
        <Route path="/" exact>
          <UsersPages />
        </Route>
        <Route path="/registration" exact>
          <RegistrationPage />
        </Route>
        <Route path="/login" exact>
          <LoginPage />
        </Route>
        <Route path="/add-user" exact>
          <AddUserPage />
        </Route>
        <Route path="/upload-users" exact>
          <UploadUsersPage />
        </Route>
        <Route path="/shopping" exact>
          <ShoppingPage />
        </Route>
        <Route path="/settings" exact>
          <SettingsPage />
        </Route>
        <Route path="/remainning-qrcodes" exact>
          <RemainQrCodesPage />
        </Route>
        <Route path="/forgot-password" exact>
          <ForgotPassword />
        </Route>
        <Route path="/reset-password" exact>
          <ResetPassword />
        </Route>
      </Switch>
    </Layout>
  );
}

export default App;
