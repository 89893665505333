
import { Link } from 'react-router-dom';
// import your icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faRightFromBracket, faCartShopping, faBars, faXmark, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useContext, useEffect, useState } from 'react';


import classes  from './MainNavigation.module.css';
import UserInfosConext from '../../store/userInfos';
import { httpLogoutAUser } from '../../requests/httpRequests';
import Cookies from 'js-cookie';

function MainNavigation() {
  const location = useLocation().pathname;
  const history = useHistory();
  const [ activeMenu, setActiveMenu ] = useState(location); //accesses, users, qrcode, mobile, account, adduser
  const [ isLoggedIn, setIsLoggedIn ] = useState(false);

  const userInfosCtx = useContext(UserInfosConext);

  async function logout() {
    const result = httpLogoutAUser();
    if(!result?.error || result.error === "loggedout") {
      Cookies.set("atriumQrcodeSession", `{"accessToken":"","refreshToken":""}`, { sameSite: "Strict" });
      userInfosCtx.setUserInfos(null);
      history.replace("/login");
    }
  }

  useEffect(() => {
    setActiveMenu(location);
  }, [location]);

  useEffect(() => {
    if (userInfosCtx.userInfos?._id) {
      console.log("logged in");
      setIsLoggedIn(true);
    } else {
      console.log("looges out", userInfosCtx.userInfos);
      setIsLoggedIn(false);
    }
  }, [userInfosCtx.userInfos])

  if (!isLoggedIn) {
    return (
      <nav className={classes.nav}>
          <div className={classes.logo}>
              <img src="/img/logo-cdvi.png" alt="logo de cdvi"></img>
          </div>
      </nav>
    )
  } else {
    return (
      <nav className={classes.nav}>
              <div className={classes.logo}>
                <img src="/img/logo-cdvi.png" alt="logo de cdvi"></img>
              </div>
              <input type="checkbox" className={`${classes.click} ${classes.input}`} id="click"></input>
              <label htmlFor="click" className={classes.menu_btn}>
                <FontAwesomeIcon icon={faBars} />
                <FontAwesomeIcon className={classes.close} icon={faXmark} />
              </label>
              <ul className={classes.ul}>
                <li onClick={() => {setActiveMenu("/")}}><Link className={ (activeMenu === "/") ? classes.active : ""} to ='/#top'>users</Link></li>
                <li onClick={() => {setActiveMenu("/add-user")}}><Link className={ (activeMenu === "/add-user") ? classes.active : ""} to ='/add-user'>Add User</Link></li>
                <li onClick={() => {setActiveMenu("/upload-users")}}><Link className={ (activeMenu === "/upload-users") ? classes.active : ""} to ='/upload-users'>Upload users</Link></li>
                <li onClick={() => {setActiveMenu("/remainning-qrcodes")}}><Link className={ (activeMenu === "/remainning-qrcodes") ? classes.active : ""} to= '/remainning-qrcodes'><FontAwesomeIcon icon={faQrcode} /><span className={classes.badge} >{userInfosCtx?.userInfos?.remainingQrCodes}</span></Link></li>
                <li onClick={() => {setActiveMenu("/shopping")}}><Link className={ (activeMenu === "/shopping") ? classes.active : ""} to ='/shopping'><FontAwesomeIcon icon={faCartShopping} /> </Link></li>
                <li onClick={() => {setActiveMenu("/settings")}}><Link className={ (activeMenu === "/settings") ? classes.active : ""} to ='/settings'><FontAwesomeIcon icon={faGear} /> </Link></li>
                <li onClick={() => {setActiveMenu("/logout"); logout()}}><Link className={ (activeMenu === "/logout") ? classes.active : ""} to ='/login'>{(userInfosCtx?.userInfos?.name)?.substring(0, 6)}. <FontAwesomeIcon icon={faRightFromBracket} /> </Link></li>
              </ul>
      </nav>
    );
  }
}

export default MainNavigation;
