import Cookies from "js-cookie";

//const API_URL = "http://localhost:3000/api/v1";
const API_URL = "https://cdvi-qr.com/api/v1";


export async function httpLogoutAUser() {
    // const tokens = document.cookie;
    // const cleanedTokens = tokens?.replace("atriumQrcodeSession=", "");
    const cleanedTokens = Cookies.get("atriumQrcodeSession");
    const jsonTokens = cleanedTokens? cleanedTokens : `{"accessToken":"","refreshToken":""}`;
    const tokensObj = JSON.parse(jsonTokens);
    const accessToken = tokensObj["accessToken"];
    console.log("AccessTocken: ", accessToken);
    if (accessToken==="") {
        return {error: "No accessToken found"};
    }
    const response = await fetch(`${API_URL}/sessions`, {
        method: "DELETE",
        headers: {
            "Authorization": "Bearer "+ accessToken
        },
    });

    const result = await response.json();
    if (result?.error === "jwt expired") {
        const result = await httpRefreshToken();
        if (result?.error) {
            Cookies.set("atriumQrcodeSession", `{"accessToken":"","refreshToken":""}`, { sameSite: "Strict" });
            return {error: "loggedout"};
        };
        // document.cookie = `atriumQrcodeSession=${JSON.stringify({accessToken: result?.accessToken, refreshToken: result?.refreshToken})}`;
        Cookies.set("atriumQrcodeSession", `${JSON.stringify({accessToken: result?.accessToken, refreshToken: result?.refreshToken})}`, { sameSite: "Strict" });
        httpLogoutAUser();
        return;
    }
    return result;
}

//if no error, return an array of active qr codes users (creted by the manager)
export async function httpGetQrcodes(page, limit) {
    // const tokens = document.cookie;
    // const cleanedTokens = tokens?.replace("atriumQrcodeSession=", "");
    const cleanedTokens = Cookies.get("atriumQrcodeSession");
    const jsonTokens = cleanedTokens ? cleanedTokens : `{"accessToken":"","refreshToken":""}`;
    const tokensObj = JSON.parse(jsonTokens);
    const accessToken = tokensObj["accessToken"];
    console.log("AccessTocken: ", accessToken);
    if (accessToken==="") {
        return {error: "No accessToken found"};
    }
    const response = await fetch(`${API_URL}/qrcodes?page=${page}&limit=${limit}`, {
        method: "GET",
        headers: {
            "Authorization": "Bearer "+ accessToken
        },
    });

    return await response.json();
}

export async function httpCreatManagerAccount(
    {name, email, password, atriumPassword, atriumLogin, atriumIPAddress, atriumPortNumber}) {
    const response = await fetch(`${API_URL}/users`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            name, email, password, atriumPassword, atriumLogin, atriumIPAddress, atriumPortNumber}),
    });

    const result = await response.json();
    return result;
}

export function getToken(type) {
    const cleanedTokens = Cookies.get("atriumQrcodeSession");
    const jsonTokens = cleanedTokens ? cleanedTokens : `{"accessToken":"","refreshToken":""}`;
    const tokensObj = JSON.parse(jsonTokens);
    if (type === "accessToken") {
        return tokensObj["accessToken"];
    }
    return tokensObj["refreshToken"];
}

export async function httpGetMangerInfos() {
    
    const accessToken = getToken("accessToken");
    if (accessToken==="") {
        return {error: "No accessToken found"};
    }
    const response = await fetch(`${API_URL}/users`, {
        method: "GET",
        headers: {
            "Authorization": "Bearer "+ accessToken
        },
    });

    const responseJson = await response.json();

    if (responseJson?.error) {
        if (responseJson.error === "jwt expired") {
            const result = await httpRefreshToken();
            if (result?.error) {
                Cookies.set("atriumQrcodeSession", `{"accessToken":"","refreshToken":""}`, { sameSite: "Strict" });
                return {error: "loggedOut"};
            }
            Cookies.set("atriumQrcodeSession", `${JSON.stringify({accessToken: result?.accessToken, refreshToken: result?.refreshToken})}`, { sameSite: "Strict" });
            const accessToken = getToken("accessToken");
            if (accessToken==="") {
                return {error: "noToken"};
            }
            const response = await fetch(`${API_URL}/users`, {
                method: "GET",
                headers: {
                    "Authorization": "Bearer "+ accessToken
                },
            });
            const responseJson = await response.json();
            return responseJson;
        }
    }
    return responseJson;
}

export async function httpLoginManager({email, password}) {
    const response = await fetch(`${API_URL}/sessions?email=${email}&password=${password}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
    });

    return await response.json();
}

export async function httpRefreshToken() {
    const refreshToken = getToken("refreshToken");
    if (refreshToken === "") {
        return {error: "noToken"};
    }
    const response = await fetch(`${API_URL}/sessions`, {
        method: "PUT",
        headers: {
            "Authorization": "Bearer "+ refreshToken,
            "Content-Type": "application/json"
        },
    });

    return await response.json();
}

export async function httpGetShoppingInfos() {
    const accessToken = getToken("accessToken");
    if (accessToken==="") {
        return {error: "No accessToken found"};
    }
    const response = await fetch(`${API_URL}/shopping`, {
        method: "GET",
        headers: {
            "Authorization": "Bearer "+ accessToken
        },
    });

    const responseJson = await response.json();

    if (responseJson?.error) {
        if (responseJson.error === "jwt expired") {
            const result = await httpRefreshToken();
            if (result?.error) {
                Cookies.set("atriumQrcodeSession", `{"accessToken":"","refreshToken":""}`, { sameSite: "Strict" });
                return {error: "loggedOut"};
            }
            Cookies.set("atriumQrcodeSession", `${JSON.stringify({accessToken: result?.accessToken, refreshToken: result?.refreshToken})}`, { sameSite: "Strict" });
            const accessToken = getToken("accessToken");
            if (accessToken==="") {
                return {error: "noToken"};
            }
            const response = await fetch(`${API_URL}/shopping`, {
                method: "GET",
                headers: {
                    "Authorization": "Bearer "+ accessToken
                },
            });
            const responseJson = await response.json();
            return responseJson;
        }
    }
    return responseJson;
}

export async function httpPayQrCodes(data) {

    const accessToken = getToken("accessToken");
    if (accessToken==="") {
        return {error: "No accessToken found"};
    }
    const response = await fetch(`${API_URL}/shopping`, {
        method: "POST",
        headers: {
            "Authorization": "Bearer "+ accessToken,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });

    const responseJson = await response.json();

    if (responseJson?.error) {
        if (responseJson.error === "jwt expired") {
            const result = await httpRefreshToken();
            if (result?.error) {
                Cookies.set("atriumQrcodeSession", `{"accessToken":"","refreshToken":""}`, { sameSite: "Strict" });
                return {error: "loggedOut"};
            }
            Cookies.set("atriumQrcodeSession", `${JSON.stringify({accessToken: result?.accessToken, refreshToken: result?.refreshToken})}`, { sameSite: "Strict" });
            const accessToken = getToken("accessToken");
            if (accessToken ==="") {
                return {error: "noToken"};
            }
            const response = await fetch(`${API_URL}/shopping`, {
                method: "POST",
                headers: {
                    "Authorization": "Bearer "+ accessToken,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
            const responseJson = await response.json();
            return responseJson;
        }
    }
    return responseJson;
};

export async function httpAddQrcodes(qrcodes) {
    const accessToken = getToken("accessToken");
    if (accessToken==="") {
        return {error: "No accessToken found"};
    }
    const response = await fetch(`${API_URL}/qrcodes`, {
        method: "POST",
        headers: {
            "Authorization": "Bearer "+ accessToken,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(qrcodes),
    });

    const responseJson = await response.json();

    if (responseJson?.error) {
        if (responseJson.error === "jwt expired") {
            const result = await httpRefreshToken();
            if (result?.error) {
                Cookies.set("atriumQrcodeSession", `{"accessToken":"","refreshToken":""}`, { sameSite: "Strict" });
                return {error: "loggedOut"};
            }
            Cookies.set("atriumQrcodeSession", `${JSON.stringify({accessToken: result?.accessToken, refreshToken: result?.refreshToken})}`, { sameSite: "Strict" });
            const accessToken = getToken("accessToken");
            if (accessToken ==="") {
                return {error: "noToken"};
            }
            const response = await fetch(`${API_URL}/qrcodes`, {
                method: "POST",
                headers: {
                    "Authorization": "Bearer "+ accessToken,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(qrcodes),
            });
            const responseJson = await response.json();
            return responseJson;
        }
    }
    return responseJson;
}

export async function httpDeleteQrcode(qrcodeId) {
    const accessToken = getToken("accessToken");
    if (accessToken==="") {
        return {error: "No accessToken found"};
    }
    const response = await fetch(`${API_URL}/qrcodes`, {
        method: "DELETE",
        headers: {
            "Authorization": "Bearer "+ accessToken,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({qrcodeId}),
    });

    const responseJson = await response.json();

    if (responseJson?.error) {
        if (responseJson.error === "jwt expired") {
            const result = await httpRefreshToken();
            if (result?.error) {
                Cookies.set("atriumQrcodeSession", `{"accessToken":"","refreshToken":""}`, { sameSite: "Strict" });
                return {error: "loggedOut"};
            }
            Cookies.set("atriumQrcodeSession", `${JSON.stringify({accessToken: result?.accessToken, refreshToken: result?.refreshToken})}`, { sameSite: "Strict" });
            const accessToken = getToken("accessToken");
            if (accessToken ==="") {
                return {error: "noToken"};
            }
            const response = await fetch(`${API_URL}/qrcodes`, {
                method: "DELETE",
                headers: {
                    "Authorization": "Bearer "+ accessToken,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({qrcodeId}),
            });
            const responseJson = await response.json();
            return responseJson;
        }
    }
    return responseJson;
}

export async function httpUpdateManagerInfos({managerId, password, newInfos}) {

}

export async function httpForgotPassword(email) {
    const response = await fetch(`${API_URL}/password/${email}`, {
        method: "GET",
    });

    const responseJson = await response.json();

    return responseJson;
}

export async function httpResetPassword({token, newPassword}) {
    const response = await fetch(`${API_URL}/password`, {
        method: "POST",
        headers: {
            "Authorization": "Bearer "+ token,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ newPassword }),
    });

    const responseJson = await response.json();

    return responseJson;
}


