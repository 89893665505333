// import your icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

import classes from "./Shopping.module.css";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import UserInfosConext from "../../store/userInfos";
import Notification from "../ui/notification";
import { getToken, httpGetShoppingInfos, httpPayQrCodes } from "../../requests/httpRequests";


// {
//     price: 25,
//     products: [
//         {name: "Pack of 10", discout: 0 },
//         {name: "Pack of 100", discout: 2 },
//         {name: "Pack of 1000", discount: 5 }
//     ]
// }

function Shopping() {
    const [ shoppingInfos, setShoppingInfos ] = useState({price: 0, products: [
        {name: "Pack of 10", discout: 0 },
        {name: "Pack of 100", discout: 0 },
        {name: "Pack of 1000", discout: 0 }
    ]});
    const [pack10, setPack10] = useState(0);
    const [pack100, setPack100] = useState(0);
    const [pack1000, setPack1000] = useState(0);
    const [requestStatus, setResquestStatus] = useState(); //'pending', 'success', or 'error'
    const [requestError, setResquestError] = useState();

    const history = useHistory();
    const userInfosCtx = useContext(UserInfosConext);

    async function payBtnHandler(e) {
        setResquestStatus("pending");
        const result = await httpPayQrCodes({products:[
            {id:0, size: pack10, name: "Pack of 10"},
            {id:1, size: pack100, name: "Pack of 100"},
            {id:2, size: pack1000, name: "Pack of 1000"}
        ]});
        if (result?.error) {
            setResquestError(result.error);
            setResquestStatus("error");
            return;
        }
        userInfosCtx.fetchUserInfos();
        setResquestStatus("success");
        setPack10(0);
        setPack100(0);
        setPack1000(0);
    }

    useEffect(() => {
        const token = getToken("accessToken");
        if (token === "") {
            history.replace("/login");
        }
        userInfosCtx.fetchUserInfos();
        async function getShoppingInfos() {
            const result = await httpGetShoppingInfos();
            if (result.error) {
                return;
            }
            setShoppingInfos(result);
        }
        getShoppingInfos();

    }, []);

    useEffect(() => {

        if(requestStatus === 'success' || requestStatus === 'error') {
            const timer = setTimeout(() => {
                setResquestStatus(null);
                setResquestError(null);
            }, 3000);

            return () => clearTimeout(timer);
        }

    }, [requestStatus]);


    let notification;

    if (requestStatus === 'pending') {
        notification = {
            status: 'pending',
            title: 'Submitting...',
            message: 'Please wait until the process finish'
        };
    }

    if (requestStatus === 'success') {
        notification = {
            status: 'success',
            title: 'Success!',
            message: 'Bought successfully!'
        };
    }

    if (requestStatus === 'error') {
        notification = {
            status: 'error',
            title: 'Error!',
            message: requestError
        };
    }

  return (
    <div className={`${classes.animate_left} ${classes.wrapper}`}>
        <div className={`${classes.cart}`}>
            <div className={classes.cart_item}>
                <div className={classes.image}>
                    <img src="img/qrcodes1.jpg" alt="..." />
                </div>
                <div className={classes.info}>
                    <h4>Pack of 10</h4>
                    <div className={classes.details}>
                        <span>{Math.floor(shoppingInfos.price/100)}<sup>{shoppingInfos.price%100}€</sup> / QR Code</span>
                    </div>
                    <div className={classes.price}>
                        <p>{Math.floor((shoppingInfos.price*10*pack10)/100)}<sup>{(shoppingInfos.price*10*pack10)%100}€</sup></p>
                        <div className={classes.counter}>
                            <FontAwesomeIcon onClick={() => {setPack10((state) => {return (state ? state-1 : 0) });}} icon={faMinus} />
                            <span className={classes.quantity}>{pack10}</span>
                            <FontAwesomeIcon onClick={() => {setPack10((state) => {return (state+1) });}} icon={faPlus} />
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.cart_item}>
                <div className={classes.image}>
                    <img src="img/qrcodes1.jpg" alt="..." />
                </div>
                <div className={classes.info}>
                    <h4>Pack of 100</h4>
                    <div className={classes.details}>
                        <span>{Math.floor((shoppingInfos.price - shoppingInfos.products[1]["discount"])/100)}<sup>{(shoppingInfos.price - shoppingInfos.products[1]["discount"])%100}€</sup> / QR Code</span>
                    </div>
                    <div className={classes.price}>
                        <p>{Math.floor(((shoppingInfos.price-shoppingInfos.products[1]["discount"])*100*pack100)/100)}<sup>{((shoppingInfos.price-shoppingInfos.products[1]["discount"])*100*pack100)%100}€</sup></p>
                        <div className={classes.counter}>
                            <FontAwesomeIcon onClick={() => {setPack100((state) => {return (state ? state-1 : 0) });}} icon={faMinus} />
                            <span className={classes.quantity}>{pack100}</span>
                            <FontAwesomeIcon onClick={() => {setPack100((state) => {return (state+1) });}} icon={faPlus} />
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.cart_item}>
                <div className={classes.image}>
                    <img src="img/qrcodes1.jpg" alt="..." />
                </div>
                <div className={classes.info}>
                    <h4>Pack of 1000</h4>
                    <div className={classes.details}>
                        <span>{Math.floor((shoppingInfos.price-shoppingInfos.products[1]["discount"])/100)}<sup>{(shoppingInfos.price-shoppingInfos.products[1]["discount"])%100}€</sup> / QR Code</span>
                    </div>
                    <div className={classes.price}>
                    <p>{Math.floor(((shoppingInfos.price-shoppingInfos.products[1]["discount"])*100*pack1000)/100)}<sup>{((shoppingInfos.price-shoppingInfos.products[1]["discount"])*100*pack1000)%100}€</sup></p>
                        <div className={classes.counter}>
                            <FontAwesomeIcon onClick={() => {setPack1000((state) => {return (state ? state-1 : 0) });}} icon={faMinus} />
                            <span className={classes.quantity}>{pack1000}</span>
                            <FontAwesomeIcon onClick={() => {setPack1000((state) => {return (state+1) });}} icon={faPlus} />
                        </div>
                    </div>
                </div>
            </div>
      
            <button onClick={payBtnHandler} className={classes.pay}> Pay {Math.floor(((shoppingInfos.price*10*pack10)+((shoppingInfos.price-shoppingInfos.products[1]["discount"])*100*pack100)+((shoppingInfos.price-shoppingInfos.products[1]["discount"])*1000*pack1000))/100)}<sup>{(((shoppingInfos.price*10*pack10)+((shoppingInfos.price-shoppingInfos.products[1]["discount"])*100*pack100)+((shoppingInfos.price-shoppingInfos.products[1]["discount"])*1000*pack1000))%100)}€</sup> <span className={classes.nbqrcodes}>for {((pack10*10)+(pack100*100)+(pack1000*1000)).toLocaleString("en-US")} QR Codes</span></button>
        </div>

        {notification && (
                <Notification
                    status={notification.status}
                    title={notification.title}
                    message={notification.message}
                />
            )}
    </div>
  );
}

export default Shopping;
