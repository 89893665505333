// import your icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from '@fortawesome/free-solid-svg-icons';

import classes from './RemainQrCodes.module.css';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useContext, useEffect, useState } from "react";
import UserInfosConext from "../../store/userInfos";

function RemainQrCodes() {
    const history = useHistory();
    const userInfosCtx = useContext(UserInfosConext);
    const [ isLoading, setIsloading ] = useState(true);

    function buyHandler(e) {
        history.replace("/shopping");
    }

    useEffect(() => {
        if (userInfosCtx?.userInfos?._id) {
            setIsloading(false);
            return;
        }
        async function getRemainingQrcodes(){
            const result = await userInfosCtx.fetchUserInfos();
            if (result?.error) {
                history.replace("/login");
            }
            setIsloading(false);
            return;
            
        }
        getRemainingQrcodes();
        
    }, []);

    if (isLoading) {
        return (
            <p>Loading...</p>
        );
    }

    return (
        <div className={classes.animate_bottom}>
            <h2 className={classes.header}>You have</h2>
            <div className={classes.badgeWrapper}>
                <FontAwesomeIcon icon={faQrcode} /><span className={classes.badge} >{userInfosCtx.userInfos?.remainingQrCodes}</span>
            </div>
            <h2 className={classes.header}>available qr codes</h2>
            <button onClick={buyHandler} className={classes.button}>Buy</button>
        </div>
    );
}

export default RemainQrCodes;