import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

import classes from './user-card.module.css';


function UserCard(props) {
//{fname: "Emmanuel ", lname:"Macron", userType:"Perm. Resident", nbTag: 3, nbQrCode:0, nbMobileCred: 0}
    const { name, email, accessLevel, validFrom, validTo, id } = props;

    async function deleteHandler(e) {
        console.log("deleting: "+id);
    }

    return <div className={classes.content}>
        <FontAwesomeIcon icon={faUserCircle} className={classes.fab}/>
        <h2>
        {`${name}`}
        </h2>
        <div className={classes.user_name} ></div>
        <ul>
            <li><span>Access level</span>{accessLevel}</li>
            <li><span>Valid from</span>{validFrom}</li>
            <li><span>Valid to</span>{validTo}</li>
            <li><span>Email</span>{email}</li>
        </ul>
        <button onClick={() => {props.deleteHandler(id)}} >Delete</button>
    </div>
}

export default UserCard;