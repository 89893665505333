import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import classes from './LoginForm.module.css';
import Notification from '../ui/notification';
import { useContext, useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';

import { isValidPassword } from '../../utils/verifications';
import { getToken, httpLoginManager, httpResetPassword } from '../../requests/httpRequests';
import UserInfosConext from '../../store/userInfos';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

function ResetPasswordForm() {
    const [requestStatus, setResquestStatus] = useState(); //'pending', 'success', or 'error'
    const [requestError, setResquestError] = useState();
    const userInfosCtx = useContext(UserInfosConext);
    const history = useHistory();
    const location = useLocation();

    let token = "";

    const confirmPasswordInputRef = useRef();
    const passwordInputRef = useRef();

    async function resetPasswordhandler(e) {
        e.preventDefault();
        const enteredPasswordConfirm = confirmPasswordInputRef.current.value;
        const enteredPassword = passwordInputRef.current.value;
        console.log(enteredPasswordConfirm, enteredPassword, token);

        if (!isValidPassword(enteredPassword)) {
            setResquestError("Invalid password! Must have at least 8 digits, 1 capital letter, 1 small letter and 1 special caracter");
            setResquestStatus("error");
            return;
        }
        if (enteredPassword != enteredPasswordConfirm) {
            setResquestError("Passwords are different!");
            setResquestStatus("error");
            return;
        }

        const response = await httpResetPassword({token, newPassword: enteredPassword});

        if (response?.error) {
            setResquestError(response.error);
            setResquestStatus("error");
            return;
        }

        history.replace("/login");
    }

    useEffect(() => {

        if(requestStatus === 'success' || requestStatus === 'error') {
            const timer = setTimeout(() => {
                setResquestStatus(null);
                setResquestError(null);
            }, 3000);

            return () => clearTimeout(timer);
        }

    }, [requestStatus]);

    useEffect(() => {
        const queryParameters = new URLSearchParams(location.search);
        token = queryParameters.get("token");
    }, []);

    let notification;

    if (requestStatus === 'pending') {
        notification = {
            status: 'pending',
            title: 'Submitting...',
            message: 'Please wait until the process finish'
        };
    }

    if (requestStatus === 'success') {
        notification = {
            status: 'success',
            title: 'Success!',
            message: 'Bought successfully!'
        };
    }

    if (requestStatus === 'error') {
        notification = {
            status: 'error',
            title: 'Error!',
            message: requestError
        };
    }

    return (
        <div className={`${classes.wrapper}`}>
            <div className={classes.container}>
                <div className={classes.title}>Reset password</div>
                <div className={classes.content}>
                    <form onSubmit={resetPasswordhandler} >
                        <div className={classes.user_details}>
                            <div className={classes.input_box}>
                                <span className={classes.details}>New password</span>
                                <input ref={passwordInputRef} type="password" placeholder="Enter new password" required />
                            </div>
                            <div className={classes.input_box}>
                                <span className={classes.details}>Confirm new password</span>
                                <input ref={confirmPasswordInputRef} type="password" placeholder="Confirm new password" required />
                            </div>
                            
                        </div>
                        <div className={classes.button}>
                            <input type="submit" value="Submit"/>
                        </div>
                        <div className={classes.messages}>
                            <p>Want to login? <Link to={"/registration"}>Click here</Link></p>
                            <p>Not registered yet? <Link to={"/registration"}>Create an account</Link></p>
                        </div>
                    </form>
                </div>
            </div>

            {notification && (
                <Notification
                    status={notification.status}
                    title={notification.title}
                    message={notification.message}
                />
            )}

        </div>
    );
}

export default ResetPasswordForm;