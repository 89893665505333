import RegistrationForm from "../components/auth/RegistrationForm";

function RegistrationPage() {
    return (
        <>
            <RegistrationForm />
        </>
    );
}

export default RegistrationPage;