
// import your icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons';

import { useContext, useEffect, useState } from "react";

import classes from "./SettingsCard.module.css";
import TextViewCard from "./TextViewCard";
import TextEditCard from "./TextEditCard";
import Notification from "../ui/notification";
import EmailTemplate from "./EmailTemplate";
import { getToken } from "../../requests/httpRequests";
import UserInfosConext from "../../store/userInfos";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

let prevText, textType, validateText;

function SettingsCard() {
    const [ managerName, setManagerName ] = useState("");
    const [ managerEmail, setManagerEmail ] = useState("");
    const [ IPAdress, setIPAdress ] = useState("75.12.45.201");
    const [ logingAtrium, setLogingAtrium ] = useState("admin");
    const [ passwordAtrium, setPasswordAtrium ] = useState("");
    const [ portNumber, setPortNumber ] = useState(80);
    const userInfosCtx = useContext(UserInfosConext);
    const history = useHistory();

    const [ isTextEditMod, setIsTextEditMod ] = useState(false);

    const [requestStatus, setResquestStatus] = useState(); //'pending', 'success', or 'error'
    const [requestError, setResquestError] = useState();
    

    function validateEmail(enteredEmail, enteredPwd) {
        console.log("Entered email: "+enteredEmail);
        //check and send to the server
        if (enteredEmail.includes("@")){
            setResquestStatus("pending");
            setIsTextEditMod(false);
            setTimeout(() => {
                setManagerEmail(enteredEmail);
                setResquestStatus("success");
            }, 500);
        }else {
            setIsTextEditMod(false);
            setResquestStatus('error');
            setResquestError("Invalid email");
        }
    }
    function validateName(enteredName, enteredPwd) {
        if (enteredName.length > 2) {
            setResquestStatus("pending");
            setIsTextEditMod(false);
            setTimeout(() => {
                setManagerName(enteredName);
                setResquestStatus("success");
            }, 500);
        } else {
            setIsTextEditMod(false);
            setResquestStatus('error');
            setResquestError("Invalid User name");
        }
    }
    function validateIPadress(enteredIpadress, enteredPwd) {
        
    }
    function validatePortNumber(enteredPortNumber, enteredPwd) {

    }
    function validateLogingAtrium(enteredLogin, enteredPwd) {

    }
    function validatePasswordAtrium(enteredPassword, enteredPwd) {

    }
    function editNameHandler() {
        prevText = managerName;
        textType = "text";
        validateText = validateName;
        setIsTextEditMod(true);
    }
    function editEmailHandler() {
        prevText = managerEmail;
        textType = "email";
        validateText = validateEmail;
        setIsTextEditMod(true);
    }
    function editIPadressHandler() {
        prevText = IPAdress;
        textType = "text";
        validateText = validateIPadress;
        setIsTextEditMod(true);
    }
    function editLogingAtriumHandler() {
        prevText = logingAtrium;
        textType = "text";
        validateText = validateLogingAtrium;
        setIsTextEditMod(true);
    }
    function editPasswordAtriumHandler() {
        prevText = passwordAtrium;
        textType = "password";
        validateText = validatePasswordAtrium;
        setIsTextEditMod(true);
    }
    function editPortNumberHandler () {
        prevText = portNumber;
        textType = "number";
        validateText = validatePortNumber;
        setIsTextEditMod(true);
    }

    function closeEditText() {
        setIsTextEditMod(false);
    }


    useEffect(() => {
        const token = getToken("accessToken");
        if (token === "") {
            history.replace("/login");
        }
        userInfosCtx.fetchUserInfos();
        setManagerName(`${userInfosCtx?.userInfos?.name}`);
        setManagerEmail(`${userInfosCtx?.userInfos?.email}`);
        setIPAdress(`${userInfosCtx?.userInfos?.atriumLogin}`);
        setLogingAtrium(`${userInfosCtx?.userInfos?.atriumIPAddress}`);
        setPortNumber(`${userInfosCtx?.userInfos?.atriumPortNumber}`);

    }, []);

    useEffect(() => {

        if(requestStatus === 'success' || requestStatus === 'error') {
            const timer = setTimeout(() => {
                setResquestStatus(null);
                setResquestError(null);
            }, 3000);

            return () => clearTimeout(timer);
        }

    }, [requestStatus]);

    let notification;

    if (requestStatus === 'pending') {
        notification = {
            status: 'pending',
            title: 'Submitting...',
            message: 'Please wait until the process finish'
        };
    }

    if (requestStatus === 'success') {
        notification = {
            status: 'success',
            title: 'Success!',
            message: 'Bought successfully!'
        };
    }

    if (requestStatus === 'error') {
        notification = {
            status: 'error',
            title: 'Error!',
            message: requestError
        };
    }

    return (
        <div className={`${classes.animate_bottom} ${classes.wrapper}`}>
            <div className={`${classes.part} ${classes.profile}`}>
                <div className={classes.img_section}>
                    <div className={classes.img_area}>
                        <div className={classes.inner_area}>
                            <FontAwesomeIcon icon={faUser} />
                        </div>
                    </div>
                </div>

                <div className={classes.part_name}> </div>
                { isTextEditMod && <TextEditCard prevText={prevText} textType={textType} validateText={validateText} closeHandler={closeEditText}/>}
                <TextViewCard title={"name"} text={managerName} editTextHandler={editNameHandler}/>
                <TextViewCard title={"email"} text={managerEmail} editTextHandler={editEmailHandler}/>
            </div>

            <div className={`${classes.part} ${classes.atrium}`}>
                <div className={classes.img_section}>
                    <div className={classes.img_area}>
                        <div className={classes.inner_area}>
                            <div className={classes.img}>
                                <img src="/img/atrium.jpg" alt="atrium product"></img>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.part_name}> </div>

                <TextViewCard title={"IP adress"} text={IPAdress} editTextHandler={editIPadressHandler}/>
                <TextViewCard title={"Port number"} text={portNumber} editTextHandler={editPortNumberHandler}/>
                <TextViewCard title={"Login"} text={logingAtrium} editTextHandler={editLogingAtriumHandler}/>
                <TextViewCard title={"Password"} text={passwordAtrium} editTextHandler={editPasswordAtriumHandler}/>
                
            </div>

            <div className={`${classes.part} ${classes.template}`}>
                <div className={classes.img_section}>
                    <div className={classes.img_area}>
                        <div className={classes.inner_area}>
                            <FontAwesomeIcon icon={faEnvelope} />
                        </div>
                    </div>
                </div>

                <div className={classes.part_name}> </div>
                
                <EmailTemplate />

            </div>


            {notification && (
                <Notification
                    status={notification.status}
                    title={notification.title}
                    message={notification.message}
                />
            )}
        </div>
    );
}

export default SettingsCard;