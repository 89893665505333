function isValidAddressIP(ip) {
    const numbers = ip?.split(".");
    if(numbers?.length !== 4) return false;
    if(numbers.some(number => { return (isNaN(number) || ( (number < 0) || (number > 255) ))})){
        return false;
    }

    return true;
}

function isValidPortNumber(port) {
    if ( isNaN(port) ) return false;
    return (port > -1) && (port < 65536);
}

function isValidPassword(password) {
    if (!password) return false;
    
    let regex = /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
    return regex.test(password);

}

function isUserDataValid(
    {name, email, password, atriumPassword, atriumLogin, atriumIPAddress, atriumPortNumber}) {
        if ( !name || (name.length < 3) || !email || (email.length < 3) || (!email.includes("@")) ||
        (!isValidPassword(password)) || !atriumPassword || (atriumPassword.length < 1) ||
        !atriumLogin || (atriumLogin.length < 1 ) /*|| !isValidAddressIP(atriumIPAddress)*/ || 
        !isValidPortNumber(atriumPortNumber))
    {
        console.log(name, email, password, atriumPassword, atriumLogin, atriumIPAddress, atriumPortNumber);
        return false;
    }
    return true;
}

function checkUserDataValidity(
    {name, email, password, atriumPassword, atriumLogin, atriumIPAddress, atriumPortNumber}
) {
    if (!name || (name.length < 3)){
        return {error: "Invalid full name"};
    }
    if (!email || (email.length < 3) || (!email.includes("@"))) {
        return {error: "Invalid email adress"};
    }

    if (!isValidPassword(password)) {
        return {error: "Password must contain at least 8 characters, one Upper case one digit and one special character"};
    }

    if (!atriumPassword || atriumPassword.length < 3) {
        return {error: "Wrong atrium password!"};
    }

    if (!atriumLogin || atriumLogin.length < 3) {
        return {error: "Wrong Atrium login!"};
    }

    if (!isValidPortNumber(atriumPortNumber)) {
        return {error: "Wrong port number!"};
    }

    if (/*!isValidAddressIP(atriumIPAddress)*/ !atriumIPAddress || atriumIPAddress.length < 4) {
        return {error: "Wrong IP adress or dns!"};
    }

    return {success: true};
}

function isValidDate(date) {
    return ( (date instanceof Date) && (isFinite(date.getTime())) );
}

function checkQRcodesValidity(qrcodes) {
    if (!qrcodes || !qrcodes?.length) {
        return false;
    }
    for (let i = 0; i < qrcodes.length; i++) {
        if ((!qrcodes[i].name) || (qrcodes[i].name?.length < 3)) {
            return {error: `The name of QR code n°${i+1} is invalid. Name=${qrcodes[i]?.name}`};
        }
        if (!qrcodes[i].accessLevel) {
            return {error: `The access level of QR code n°${i+1} is invalid. Access Level=${qrcodes[i]?.accessLevel}`};
        }
        if ((!qrcodes[i].email) || (!qrcodes[i].email?.includes("@"))) {
            return {error: `The email of QR code n°${i+1} is invalid. Email=${qrcodes[i]?.email}`};
        }
        if ((!qrcodes[i].validFrom) || (!isValidDate(qrcodes[i].validFrom))) {
            return {error: `The activation date/time of QR code n°${i+1} is invalid. Date=${qrcodes[i]?.validFrom}`};
        }
        if ((!qrcodes[i].validTo) || (!isValidDate(qrcodes[i].validTo))) {
            return {error: `The deactivation date/time of QR code n°${i+1} is invalid. Date=${qrcodes[i]?.validTo}`};
        }
        if (qrcodes[i].validFrom > qrcodes[i].validTo) {
            return {error: `The deactivation date/time must be greater than the activation date/time. QR code n°${i+1}`};
        }
    }
    
    return {success: true};
}

module.exports = {
    isValidAddressIP,
    isValidPortNumber,
    checkQRcodesValidity,
    isValidPassword,
    checkUserDataValidity,
}