import { read, utils } from "xlsx";
import classes from "./ImportCSV.module.css";
import { formatTime } from "../../utils/dates";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function ImportCSV(props) {
    let uploadedUsers = [];

    function importHandler(event) {
        const files = event.target.files;

        if(files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;

                if (sheets.length) {
                    const rows = utils.sheet_to_csv(wb.Sheets[sheets[0]]);
                    const lines = rows.split("\n");
                    console.log("Lines: ", lines.length);
                    const newUsers = [];
                    
                    for(let i = 1; i < lines.length; i++) {
                        const userInfos = lines[i].split(',');
                        //check info validity

                        //after check
                        if (userInfos[0] && userInfos[1] && userInfos[2] && userInfos[3] && userInfos[4] && userInfos[5] && userInfos[6]) {
                            newUsers.push({
                                name: userInfos[0],
                                email: userInfos[1],
                                validFrom: new Date(userInfos[2]+ "T"+formatTime(userInfos[3])),
                                validTo: new Date(userInfos[4]+ "T"+formatTime(userInfos[5])),
                                validFromLocalD: userInfos[2],
                                validFromLocalT: formatTime(userInfos[3]),
                                validToLocalD: userInfos[4],
                                validToLocalT: formatTime(userInfos[5]),
                                accessLevel: userInfos[6],
                            });
                        }
                    }

                    uploadedUsers = newUsers;
                }   
            };
            reader.readAsArrayBuffer(file);
        }
    }

    function submitHandler(event) {
        event.preventDefault();
        props.onAddUsers(uploadedUsers);
    }

    return (
        <section className={classes.animate_bottom}>
            <h2 className={classes.header}>Upload the csv or excel file</h2>
            <div className={classes.form_container}>
                <h4>Make sure your file has the correct format. You can download a sample file by clicking <Link to="/userFileExample.xlsx" target="_blank">here</Link> </h4>
                <form onSubmit={submitHandler} >
                    <label htmlFor="file">Select a file</label>
                    <input onChange={importHandler} type="file" name="file" className="" id="file" required accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"></input>
                    <input disabled={false} type="submit" value="Submit" />
                </form>
            </div>
        </section>
    );
}

export default ImportCSV; 