import Shopping from "../components/shopping/Shopping";

function ShoppingPage() {
    return (
        <section>
            <Shopping />
        </section>
    );
}

export default ShoppingPage;