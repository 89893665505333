import { createContext, useState } from "react"; 
import { httpGetMangerInfos } from "../requests/httpRequests";

const UserInfosConext = createContext({
    userInfos: {},
    fetchUserInfos: async () => {},
    getUserInfos: () => {},
    setUserInfos: () => {},
});

export function UserInfosConextProvider(props) {
    const [ user, setUser ] = useState({});

    function getUserInfosHandler (usr) {
        return (usr);
    }
    function setUserInfosHandler (usr) {
        console.log("setting user in fos");
        setUser((prev) => {return usr});
    }

    async function fetchUserInfosHandler () {
        console.log("fetching...");
        const result = await httpGetMangerInfos();
        console.log("fecting result: ", result, "_id=", result?._id);
        if (result._id) {
            setUser(result);
            return {error: null};
        }
        return {error: "No user fetched"};
    }

    const context = {
        userInfos: user,
        getUserInfos: getUserInfosHandler,
        fetchUserInfos: fetchUserInfosHandler,
        setUserInfos: setUserInfosHandler,
    };

    return (
        <UserInfosConext.Provider value={context}>
            {props.children}
        </UserInfosConext.Provider>
    );
}

export default UserInfosConext;