
import { useContext, useEffect, useState } from "react";
import UserCardList from "../components/users/user-card-list";
import Notification from "../components/ui/notification";
import { httpGetQrcodes, httpRefreshToken } from "../requests/httpRequests";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Cookies from "js-cookie";

import UserInfosConext from "../store/userInfos";

function UsersPages() {
    const history = useHistory();
    const [ isLoading, setIsloading ] = useState(true);
    const [ users, setUsers ] = useState([]);
    const [requestStatus, setResquestStatus] = useState(); //'pending', 'success', or 'error'
    const [requestError, setResquestError] = useState();

    const userInfosCtx = useContext(UserInfosConext);

    async function getQrcodes(page, limit) {
        const result = await httpGetQrcodes(page, limit);
        console.log("result: ",result);
        if (result?.error) {
            console.log("result1: ", result?.error);
            if (result?.error === "jwt expired") {
                //refresh the token
                const result = await httpRefreshToken();
                console.log(result);
                if (result?.error) {
                    // document.cookie = `atriumQrcodeSession={"accessToken":"","refreshToken":""}`;
                    Cookies.set("atriumQrcodeSession", `{"accessToken":"","refreshToken":""}`, { sameSite: "Strict" });
                    history.replace("/login");
                    return
                };
                // document.cookie = `atriumQrcodeSession=${JSON.stringify({accessToken: result?.accessToken, refreshToken: result?.refreshToken})}`;
                Cookies.set("atriumQrcodeSession", `${JSON.stringify({accessToken: result?.accessToken, refreshToken: result?.refreshToken})}`, { sameSite: "Strict" });
                getQrcodes(page, limit);
                return;
            }
            
            setResquestError("Page number and limit are required");
            setResquestStatus("error");
            // document.cookie = `atriumQrcodeSession={"accessToken":"","refreshToken":""}`;
            Cookies.set("atriumQrcodeSession", `{"accessToken":"","refreshToken":""}`, {  sameSite: "Strict" });
            history.replace("/login");

            return;
        }
        result.forEach(user => {user.validFrom = (new Date(user.validFrom)).toLocaleString(); user.validTo = (new Date(user.validTo)).toLocaleString()});
        setUsers(result);
        setResquestStatus("success");
        setIsloading(false);
        return;
    }

    useEffect(() => {
        
        userInfosCtx.fetchUserInfos();
        getQrcodes(1, 0);

        setResquestStatus("pending");
        
    }, []);

    useEffect(() => {

        if(requestStatus === 'success' || requestStatus === 'error') {
            const timer = setTimeout(() => {
                setResquestStatus(null);
                setResquestError(null);
            }, 500);

            return () => clearTimeout(timer);
        }

    }, [requestStatus]);


    let notification;

    if (requestStatus === 'pending') {
        notification = {
            status: 'pending',
            title: 'Loading users...',
            message: 'Please wait until the process finish'
        };
    }

    if (requestStatus === 'success') {
        notification = {
            status: 'success',
            title: 'Success!',
            message: 'Bought successfully!'
        };
    }

    if (requestStatus === 'error') {
        notification = {
            status: 'error',
            title: 'Error!',
            message: requestError
        };
    }
    if (isLoading) {
       return <>
            <p>Loading...</p>
            {notification && (
                <Notification
                    status={notification.status}
                    title={notification.title}
                    message={notification.message}
                />
            )}
       </>
    }

    return <div>
        <UserCardList listTitle={"Users"} users={users}/>
    </div>;
}

export default UsersPages;