
// import your icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop, faMobileAndroidAlt, faPenToSquare } from '@fortawesome/free-solid-svg-icons';

import classes from "./EmailTemplate.module.css";
import { useState } from "react";
//mobile mode (iphone 10)375px

const API_URL = "https://cdvi-qr.com/api/v1";

function EmailTemplate() {
    const [ activeView, setActiveView ] = useState("desktop");

    return (
        <div className={classes.container}>

            <p className={classes.actions}><span>Company logo<FontAwesomeIcon icon={faPenToSquare}/></span> <span>Title<FontAwesomeIcon icon={faPenToSquare}/></span> <span>Texte<FontAwesomeIcon icon={faPenToSquare}/></span> <span>Color<FontAwesomeIcon icon={faPenToSquare}/></span></p>
            <p className={classes.toggle_views}><span className={classes.title}>Preview</span> <span onClick={(e) => {setActiveView("desktop")}} className={`${classes.desktop} ${(activeView === "desktop") ? classes.active_view : ""}`}><FontAwesomeIcon icon={faDesktop}/>Desktop</span> <span onClick={(e) => {setActiveView("mobile")}} className={`${classes.mobile} ${(activeView === "mobile") ? classes.active_view : ""}`}><FontAwesomeIcon icon={faMobileAndroidAlt}/>Mobile</span></p>
            <iframe src={`${API_URL}/emailtemplate`} title="email-template" width={`${(activeView === "desktop") ? "100%" : "375px"}`} height={"600px"}></iframe>
            
        </div>
    );
}

export default EmailTemplate;