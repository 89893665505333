import classes from './user-card-list.module.css';
import Card from './user-card';
import { useEffect, useState } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Notification from '../ui/notification';
import { httpDeleteQrcode } from '../../requests/httpRequests';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function UserCardList(props) {
    const { listTitle, users } = props;
    const [ filteredUsers, setFilteredUsers ] = useState(users);
    const history = useHistory();

    const [requestStatus, setResquestStatus] = useState(); //'pending', 'success', or 'error'
    const [requestError, setResquestError] = useState();
    
    function updateDisplayedUsr(e) {
        const newUsersList = users.filter(usr => usr.name.includes(e.target.value));
        setFilteredUsers(newUsersList);
    }

    async function deleteHandler(id) {
        setResquestStatus("pending");
        const result = await httpDeleteQrcode(id);
        if (result?.error) {
            setResquestError(result.error);
            setResquestStatus("error");
            return;
        }
        setResquestStatus("success");
        history.replace("/login");//to reload qr users
    }


    useEffect(() => {

        if(requestStatus === 'success' || requestStatus === 'error') {
            const timer = setTimeout(() => {
                setResquestStatus(null);
                setResquestError(null);
            }, 3000);

            return () => clearTimeout(timer);
        }

    }, [requestStatus]);


    let notification;

    if (requestStatus === 'pending') {
        notification = {
            status: 'pending',
            title: 'Deleting...',
            message: 'Please wait until the process finish'
        };
    }

    if (requestStatus === 'success') {
        notification = {
            status: 'success',
            title: 'Success!',
            message: 'Deleted successfully!'
        };
    }

    if (requestStatus === 'error') {
        notification = {
            status: 'error',
            title: 'Error!',
            message: requestError
        };
    }

    return <div className={classes.animate_bottom}>
        <h2 id='top' className={classes.header}>{listTitle}</h2>
        <div className={classes.search_field}>
            <input onChange={updateDisplayedUsr}  type='text' placeholder='Search...'></input>
            <FontAwesomeIcon icon={faSearch} />
        </div>
        <div className={classes.list}>
            {!filteredUsers.length && <p>No user found</p>}
            {filteredUsers.map((user) => <Card deleteHandler={deleteHandler} key={`${user._id}`} name={user.name} email={user.email} accessLevel={user.accessLevel} validFrom={user.validFrom} validTo={user.validTo} id={user._id}/>)}
        </div>

        {notification && (
                <Notification
                    status={notification.status}
                    title={notification.title}
                    message={notification.message}
                />
            )}
    </div>

}

export default UserCardList;