import SettingsCard from "../components/settings/SettingsCard";

function SettingsPage() {
    return (
        <section>
            <SettingsCard />
        </section>
    );
}

export default SettingsPage;