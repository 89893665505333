import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import classes from './LoginForm.module.css';
import Notification from '../ui/notification';
import { useContext, useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';

import { isValidPassword } from '../../utils/verifications';
import { getToken, httpLoginManager } from '../../requests/httpRequests';
import UserInfosConext from '../../store/userInfos';

function LoginForm() {
    const [requestStatus, setResquestStatus] = useState(); //'pending', 'success', or 'error'
    const [requestError, setResquestError] = useState();
    const userInfosCtx = useContext(UserInfosConext);
    const history = useHistory();

    const emailInputRef = useRef();
    const passwordInputRef = useRef();

    async function forgotPasswordSubHandler(e) {
        e.preventDefault();

        setResquestStatus("pending");

        const enteredEmail = emailInputRef.current.value;
        const enteredPassword = passwordInputRef.current.value;
        console.log(enteredEmail, enteredPassword);
        if ( !enteredEmail || (enteredEmail.length < 3) || !(enteredEmail.includes("@")) ) {
            setResquestError("Invalid email!");
            setResquestStatus("error");
            return;
        }
        if (!isValidPassword(enteredPassword)) {
            setResquestError("Invalid password! Must have at least 8 digits, 1 capital letter, 1 small letter and 1 special caracter");
            setResquestStatus("error");
            return;
        }

        const response = await httpLoginManager({email: enteredEmail, password: enteredPassword});

        if (response?.error) {
            setResquestError(response.error);
            setResquestStatus("error");
            return;
        }
        setResquestStatus("success");
        userInfosCtx.setUserInfos(response?.user);
        console.log(response);
        Cookies.set("atriumQrcodeSession", `${JSON.stringify({accessToken: response?.accessToken, refreshToken: response?.refreshToken})}`, { sameSite: "Strict" })
        history.replace("/");
    }

    useEffect(() => {

        if(requestStatus === 'success' || requestStatus === 'error') {
            const timer = setTimeout(() => {
                setResquestStatus(null);
                setResquestError(null);
            }, 3000);

            return () => clearTimeout(timer);
        }

    }, [requestStatus]);

    useEffect(() => {
        const token = getToken("accessToken");
        if (token !== "") {
            userInfosCtx.fetchUserInfos();
            history.replace("/");
        }
    }, []);

    let notification;

    if (requestStatus === 'pending') {
        notification = {
            status: 'pending',
            title: 'Submitting...',
            message: 'Please wait until the process finish'
        };
    }

    if (requestStatus === 'success') {
        notification = {
            status: 'success',
            title: 'Success!',
            message: 'Successfully connected!'
        };
    }

    if (requestStatus === 'error') {
        notification = {
            status: 'error',
            title: 'Error!',
            message: requestError
        };
    }

    return (
        <div className={`${classes.wrapper}`}>
            <div className={classes.container}>
                <div className={classes.title}>Login</div>
                <div className={classes.content}>
                    <form onSubmit={forgotPasswordSubHandler} >
                        <div className={classes.user_details}>
                            <div className={classes.input_box}>
                                <span className={classes.details}>Email</span>
                                <input ref={emailInputRef} type="text" placeholder="Enter your email" required />
                            </div>
                            <div className={classes.input_box}>
                                <span className={classes.details}>Password</span>
                                <input ref={passwordInputRef} type="password" placeholder="Enter your password" required />
                            </div>
                            
                        </div>
                        <div className={classes.messages}>
                            <p>Forgot password? <Link to={"/forgot-password"}>Click here</Link></p>
                        </div>
                        <div className={classes.button}>
                            <input type="submit" value="Login"/>
                        </div>
                        <div className={classes.messages}>
                            <p>Not registered yet? <Link to={"/registration"}>Create an account</Link></p>
                        </div>
                    </form>
                </div>
            </div>

            {notification && (
                <Notification
                    status={notification.status}
                    title={notification.title}
                    message={notification.message}
                />
            )}

        </div>
    );
}

export default LoginForm;