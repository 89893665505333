import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import classes from './LoginForm.module.css';
import Notification from '../ui/notification';
import { useContext, useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';

import { isValidPassword } from '../../utils/verifications';
import { getToken, httpForgotPassword, httpLoginManager } from '../../requests/httpRequests';
import UserInfosConext from '../../store/userInfos';

function ForgotPasswordForm() {
    const [requestStatus, setResquestStatus] = useState(); //'pending', 'success', or 'error'
    const [requestError, setResquestError] = useState();
    const userInfosCtx = useContext(UserInfosConext);
    const history = useHistory();

    const emailInputRef = useRef();

    async function submitHandler(e) {
        e.preventDefault();
        const enteredEmail = emailInputRef.current.value;
        console.log(enteredEmail);
        if ( !enteredEmail || (enteredEmail.length < 3) || !(enteredEmail.includes("@")) ) {
            setResquestError("Invalid email!");
            setResquestStatus("error");
            return;
        }

        const response = await httpForgotPassword(enteredEmail);
        console.log(response);
        if (response?.error) {
            setResquestError(response.error);
            setResquestStatus("error");
            return;
        }

        setResquestStatus("success");
        emailInputRef.current.value = "";
    }

    useEffect(() => {

        if(requestStatus === 'success' || requestStatus === 'error') {
            const timer = setTimeout(() => {
                setResquestStatus(null);
                setResquestError(null);
            }, 3000);

            return () => clearTimeout(timer);
        }

    }, [requestStatus]);

    let notification;

    if (requestStatus === 'pending') {
        notification = {
            status: 'pending',
            title: 'Submitting...',
            message: 'Please wait until the process finish'
        };
    }

    if (requestStatus === 'success') {
        notification = {
            status: 'success',
            title: 'Success!',
            message: 'Email sent successfuly!'
        };
    }

    if (requestStatus === 'error') {
        notification = {
            status: 'error',
            title: 'Error!',
            message: requestError
        };
    }

    return (
        <div className={`${classes.wrapper}`}>
            <div className={classes.container}>
                <div className={classes.title}>Forgot password</div>
                <div className={classes.content}>
                    <form onSubmit={submitHandler} >
                        <div className={classes.user_details}>
                            <div className={classes.input_box}>
                                <span className={classes.details}>Email</span>
                                <input ref={emailInputRef} type="text" placeholder="Enter your email" required />
                            </div>
                            
                        </div>
                        
                        <div className={classes.button}>
                            <input type="submit" value="Submit"/>
                        </div>
                        <div className={classes.messages}>
                            <p>Want to login? <Link to={"/login"}>Click here</Link></p>
                            <p>Not registered yet? <Link to={"/registration"}>Create an account</Link></p>
                        </div>
                    </form>
                </div>
            </div>

            {notification && (
                <Notification
                    status={notification.status}
                    title={notification.title}
                    message={notification.message}
                />
            )}

        </div>
    );
}

export default ForgotPasswordForm;