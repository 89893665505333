import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useContext, useEffect, useState } from "react";

import ImportCSV from "../components/upload-users/ImportCSV";
import Notification from "../components/ui/notification";
import { checkQRcodesValidity } from "../utils/verifications";
import { getToken, httpAddQrcodes } from "../requests/httpRequests";
import UserInfosConext from "../store/userInfos";

function UploadUsersPage() {
    const history = useHistory();
    const [ requestStatus, setResquestStatus] = useState(); //'pending', 'success', or 'error'
    const [ requestError, setResquestError ] = useState();
    const userInfosCtx = useContext(UserInfosConext);
    
    useEffect(() => {
        const token = getToken("accessToken");
        if (token === "") {
            history.replace("/login");
        }
        userInfosCtx.fetchUserInfos();

    }, []);

    useEffect(() => {

        if(requestStatus === 'success' || requestStatus === 'error') {
            const timer = setTimeout(() => {
                setResquestStatus(null);
                setResquestError(null);
            }, 3000);

            return () => clearTimeout(timer);
        }

    }, [requestStatus]);

    async function onAddUsers(usersData) {
        // console.log(usersData);
        setResquestStatus("pending");
        const checkingResult = checkQRcodesValidity(usersData);
        if ( checkingResult?.error ) {
            setResquestError(checkingResult?.error);
            setResquestStatus("error");
            console.log(usersData);
            return;
        }
        const result = await httpAddQrcodes({qrcodes: usersData});
        if (result?.error) {
            console.log(result);
            setResquestError(result.error);
            setResquestStatus("error");
            return;
        }
        console.log("Result: ", result);
        setResquestStatus("success");
    }


    let notification;

    if(requestStatus === 'pending') {
        notification = {
            status: 'pending',
            title: 'Submitting...',
            message: 'Please wait until the process finish'
        };
    }

    if(requestStatus === 'success') {
        notification = {
            status: 'success',
            title: 'Success!',
            message: 'User(s) created successfuly'
        };
    }

    if(requestStatus === 'error') {
        notification = {
            status: 'error',
            title: 'Error!',
            message: requestError
        };
    }

    return (
        <section>
            <ImportCSV onAddUsers={onAddUsers}/>

            {notification && (
                <Notification
                    status={notification.status}
                    title={notification.title}
                    message={notification.message}
                />
            )}
        </section>
    );
}

export default UploadUsersPage;