import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import classes from "./RegistrationForm.module.css";
import Notification from "../ui/notification";
import { useContext, useEffect, useRef, useState } from "react";

import { checkUserDataValidity, isUserDataValid } from "../../utils/verifications";
import { getToken, httpCreatManagerAccount } from "../../requests/httpRequests";
import UserInfosConext from "../../store/userInfos";

function RegistrationForm() {

    const [requestStatus, setResquestStatus] = useState(); //'pending', 'success', or 'error'
    const [requestError, setResquestError] = useState();
    const userInfosCtx = useContext(UserInfosConext);
    const history = useHistory();
    
    const fullNameInputRef = useRef();
    const emailInputRef = useRef();
    const passwordInputRef = useRef();
    const confirmPasswordInputRef = useRef();
    const atriumIPInputRef = useRef();
    const atriumPortInputRef = useRef();
    const atriumLoginInputRef = useRef();
    const atriumPasswordInputRef = useRef();

    async function submitHandler(e) {
        e.preventDefault();

        setResquestStatus("pending");

        const enteredFullName = fullNameInputRef.current.value;
        const enteredEmail=  emailInputRef.current.value;
        const enteredPassword = passwordInputRef.current.value;
        const enteredConfirmPassword = confirmPasswordInputRef.current.value;
        const enteredAtriumIP = atriumIPInputRef.current.value;
        const enteredAtriumPort = atriumPortInputRef.current.value;
        const enteredAtriumLogin = atriumLoginInputRef.current.value;
        const enteredAtriumPassword = atriumPasswordInputRef.current.value;
        
        const user = {
            name: enteredFullName,
            email: enteredEmail,
            password: enteredPassword,
            atriumIPAddress: enteredAtriumIP,
            atriumLogin: enteredAtriumLogin,
            atriumPassword: enteredAtriumPassword,
            atriumPortNumber: enteredAtriumPort,
        }

        const checkresult = checkUserDataValidity(user);
        if (checkresult.error) {
            setResquestError(checkresult.error);
            setResquestStatus("error");
            return;
        }
        if (enteredConfirmPassword !== enteredPassword) {
            setResquestError("The two passwords are different");
            setResquestStatus("error");
            return;
        }

        const result = await httpCreatManagerAccount(user);
        if(result?.error) {
            setResquestError(result.error);
            setResquestStatus("error");
            return;
        }
        setResquestStatus("success");
        setTimeout(() => {
            history.replace("/login");
        }, 500);
    }

    useEffect(() => {
        const token = getToken("accessToken");
        console.log("stored: ",token);
        if (token !== "") {
            userInfosCtx.fetchUserInfos();
            history.replace("/");
        }
    }, []);

    useEffect(() => {

        if(requestStatus === 'success' || requestStatus === 'error') {
            const timer = setTimeout(() => {
                setResquestStatus(null);
                setResquestError(null);
            }, 3000);

            return () => clearTimeout(timer);
        }

    }, [requestStatus]);

    let notification;

    if (requestStatus === 'pending') {
        notification = {
            status: 'pending',
            title: 'Submitting...',
            message: 'Please wait until the process finish'
        };
    }

    if (requestStatus === 'success') {
        notification = {
            status: 'success',
            title: 'Success!',
            message: 'Successfully created!'
        };
    }

    if (requestStatus === 'error') {
        notification = {
            status: 'error',
            title: 'Error!',
            message: requestError
        };
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.container}>
                <div className={classes.title}>Registration</div>
                <div className={classes.content}>
                    <form onSubmit={submitHandler} >
                        <div className={classes.user_details}>
                            <div className={classes.input_box}>
                                <span className={classes.details}>Full Name</span>
                                <input ref={fullNameInputRef} type="text" placeholder="Enter your name" required />
                            </div>
                            <div className={classes.input_box}>
                                <span className={classes.details}>Email</span>
                                <input ref={emailInputRef} type="text" placeholder="Enter your email" required />
                            </div>
                            <div className={classes.input_box}>
                                <span className={classes.details}>Password</span>
                                <input ref={passwordInputRef} type="password" placeholder="Enter your password" required />
                            </div>
                            <div className={classes.input_box}>
                                <span className={classes.details}>Confirm Password</span>
                                <input ref={confirmPasswordInputRef} type="password" placeholder="Confirm your password" required />
                            </div>
                            <div className={classes.input_box}>
                                <span className={classes.details}>Atrium IP address or domaine name</span>
                                <input ref={atriumIPInputRef} type="text" placeholder="XY.XY.XY.XY" required />
                            </div>
                            <div className={classes.input_box}>
                                <span className={classes.details}>Atrium Port</span>
                                <input ref={atriumPortInputRef} type="number" placeholder="80" required />
                            </div>
                            <div className={classes.input_box}>
                                <span className={classes.details}>Atrium Login</span>
                                <input ref={atriumLoginInputRef} type="text" placeholder="Enter Atrium Login" required />
                            </div>
                            <div className={classes.input_box}>
                                <span className={classes.details}>Atrium Password</span>
                                <input ref={atriumPasswordInputRef} type="password" placeholder="Enter Atrium Password" required />
                            </div>
                        </div>
                        <div className={classes.messages}>
                            <p>Aleady have an account? <Link to={"/login"}>Login here</Link></p>
                        </div>
                        <div className={classes.button}>
                            <input type="submit" value="Register"/>
                        </div>
                    </form>
                </div>
            </div>

            {notification && (
                <Notification
                    status={notification.status}
                    title={notification.title}
                    message={notification.message}
                />
            )}

        </div>
    );
}

export default RegistrationForm;