
// import your icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';

import classes from "./TextViewCard.module.css";

function TextViewCard(props) {
    const { title, text, editTextHandler } = props;
    return(
        <div className={classes.card}>
            <p className={classes.title}>{title}</p>
            <p className={classes.texte}>{text}</p>
            <button onClick={editTextHandler}><FontAwesomeIcon icon={faPenToSquare} /></button>
        </div>
    );
}

export default TextViewCard;