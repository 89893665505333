export function formatTime(strTime) {
    if (strTime.length === 5) return strTime;
    const parts = strTime.split(":");
    console.log("Parts: ", parts);
    if (parts[0].length < 2) {
        parts[0] = "0"+parts[0];
    }
    if (parts[1].length < 2) {
        parts[1] = "0"+parts[1];
    }
    return parts[0]+":"+parts[1];
} 