
// import your icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import classes from "./TextEditCard.module.css";
import { useRef } from "react";

function TextEditCard(props) {
    const { prevText, textType, validateText, closeHandler } = props;
    const textInputref = useRef();
    const pwdInputRef = useRef();

    return (
        <div className={classes.container}>
            <div onClick={closeHandler} className={classes.backdrop}></div>
            <div className={classes.modal}>
                <FontAwesomeIcon onClick={closeHandler} icon={faXmark} />
                <div>
                    <input ref={textInputref} required type={textType} placeholder={prevText} ></input>
                </div>
                <div>
                    <input ref={pwdInputRef} type="password" placeholder="Your password here.." ></input>
                </div>
                <div>
                    <button onClick={(e) => {validateText(textInputref.current.value, pwdInputRef.current.value);}}>Submit</button>
                </div>
            </div>
        </div>
    );
}

export default TextEditCard;