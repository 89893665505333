import { useRef } from "react";
import classes from "./AddUserForm.module.css";

function AddUserForm(props) {

    const fullNameInputRef = useRef();
    const emailInputref = useRef();
    const validFromDInputRef = useRef();
    const validFromTInputRef = useRef();
    const validToDInputRef = useRef();
    const validToTInputref = useRef();
    const accessLevelInputRef = useRef();

    function formSubHandler(event) {
        event.preventDefault();
        const enteredFullName = fullNameInputRef.current.value;
        const enteredEmail = emailInputref.current.value;
        const enteredValidFromD = validFromDInputRef.current.value;
        const enteredValidFromT = validFromTInputRef.current.value;
        const enteredValidToD = validToDInputRef.current.value;
        const enteredValidToT = validToTInputref.current.value;
        const enteredAccessLevel = accessLevelInputRef.current.value;

        const userData = {
            name: enteredFullName,
            email: enteredEmail,
            validFrom: new Date(enteredValidFromD+"T"+enteredValidFromT),
            validTo: new Date(enteredValidToD+"T"+enteredValidToT),
            validFromLocalD: enteredValidFromD,
            validFromLocalT: enteredValidFromT,
            validToLocalD: enteredValidToD,
            validToLocalT: enteredValidToT,
            accessLevel: enteredAccessLevel,
        };

        props.onAddNewUser(userData);
    }

    return (
        <div className={classes.animate_bottom}>
            <h2 className={classes.header}>Adding a new user</h2>
            <div className={classes.form_container}>
                <form onSubmit={formSubHandler}>
                    <label htmlFor="name">User full name</label>
                    <input required type="text" id="name" name="name" placeholder="Full name.." ref={fullNameInputRef}/>

                    <label htmlFor="email">User email</label>
                    <input required type="email" id="email" name="email" placeholder="Email.." ref={emailInputref}/>

                    <label htmlFor='validfromD'>Valid fom</label>
                    <div className={classes.validity}>
                        <input required type="date" id='validfromD' name='validfromD'  ref={validFromDInputRef}></input>
                        <input required type="time"  ref={validFromTInputRef}/>
                    </div>

                    <label htmlFor='validfromT'>Valid to</label>
                    <div className={classes.validity}>
                        <input required type="date" id='validfromT' name='validfromT' ref={validToDInputRef}></input>
                        <input required type="time" ref={validToTInputref}/>
                    </div>

                    <label htmlFor="accesslevel">Access Level</label>
                    <select id="accesslevel" name="accesslevel" ref={accessLevelInputRef}>
                        <option value="Always">Always</option>
                        <option value="Access Lvl 1">Access Lvl 1</option>
                        <option value="Access Lvl 2">Access Lvl 2</option>
                    </select>

                    <input disabled={false} type="submit" value="Submit" />
                </form>
            </div>
        </div>
    );
}

export default AddUserForm;